import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, navigate } from "gatsby";
import { Header } from "../components/Header/Header";
import logoWhite from "../images/logo-white.png";
import { MuiTelInput } from 'mui-tel-input';

import { 
  ArrowLeftCircleIcon,
} from '@heroicons/react/24/outline'
import { useFormik } from "formik";
import axios from "axios";
import toast from "react-hot-toast";
import { Footer } from "../components/Footer/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/rootReducer";
import StrongPassword from "../components/StrongPassword/StrongPassword";
import { Loader } from "../components/Loader/Loader";

const Students = () => {
  const userName = typeof window !== 'undefined' && localStorage.getItem('name');
  const [signed,setSigned] = useState(false);
  const [validPassword, setValidPassword] = useState<boolean>(false);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false); 
  
  const formikCreate = useFormik({
		enableReinitialize: true,
		initialValues: {
			firstName: "",
			lastName: "",
      password: "",
			email: "",
			mobilePhone: "",
      profile_url: ""
		},
		validate: (values) => {
			const errors: { firstName?: string; lastName?: string; email?: string; mobilePhone?: string; password?: string } = {};

			if (!values.firstName) {
				errors.firstName = 'Required';
			}

      if (!values.password || !validPassword) {
				errors.password = 'Required';
			}

			if (!values.lastName) {
				errors.lastName = 'Required';
			}

      if (!values.email) {
				errors.email = 'Required';
			}

      if (values.email && values.email && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email))) {
        errors.email = 'Required';
      }

			if (!values.mobilePhone) {
				errors.mobilePhone = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values: any) => {
      setSubmitLoader(true);
      let data = new FormData();
      data.append('firstName', formikCreate.values.firstName);
      data.append('lastName', formikCreate.values.lastName);
      data.append('email', formikCreate.values.email);
      data.append('password', formikCreate.values.password);
      data.append('mobilePhone', formikCreate.values.mobilePhone);
      data.append('group', "Unidad");
      data.append('timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
      if(formikCreate.values.profile_url){
        data.append('img', formikCreate.values.profile_url, "imagen.png");
      }
      await createUser(data);
      setSubmitLoader(false);
		}
	});

  const createUser = async (data: any) => {
    let token = localStorage.getItem("access_token");
		await axios({
			method: 'post',
			url: process.env.API_URL + '/api/admin-group/users',
			headers: {
				Authorization : `Bearer ${token}`
			},
			data: data
		})
		.then( (response: any) => {
			if(response.data.status){
        toast.success('Successfully created')
				setTimeout( () => {
					return navigate("/students")
				}, 500)
			}
      else{
        toast.error("Something went wrong, try again")
      }
		})
		.catch( (error: any) => {
      toast.error("Something went wrong, try again")
			console.log(error)
		});
  };

  const loginElement: any = useSelector((state: RootState) => state.loginSlice);

  const handlePassword = (data: boolean) => {
    setValidPassword(data);
    console.log(validPassword);
  }

  useEffect( () => {
    if(loginElement.items.status) {
      setSigned(true)
    } else {
      navigate("/")
    }
  },[loginElement]);
  
  return (
    <Layout signed={signed}>
      <div className="bg-[#f2f2f2]">
        <div>
          {/* header */}
          {/* <Header isSignIn={signed} /> */}

          <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[40px] lg:pb-0">
            <div className="lg:flex lg:items-center lg:justify-between pb-10">
              <div className="flex items-center">
                <img src="" alt="" />
                <div className="mb-4 md:mb-0">
                  <div className="flex items-center mb-2">
                    <Link to="/students" className="flex items-center">
                      <ArrowLeftCircleIcon className="h-6 w-6"/>
                      <span className="ff-cg--medium ml-2">BACK TO STUDENTS</span>
                    </Link>
                  </div>
                  <h2 className="text-[30px] lg:text-[60px] ff-cg--semibold leading-none">Add Student</h2>
                </div>
              </div>
            </div>
            <div className="grid gap-5 lg:gap-10 lg:grid-cols-12">
            <form onSubmit={formikCreate.handleSubmit} className="col-span-12 grid gap-5 lg:gap-10 lg:grid-cols-12">
              <div className="col-span-12">
                <div className="rounded-md bg-white shadow-lg p-[15px] md:p-[30px] pb-10 md:pb-16">
                  <div className="grid gap-4 lg:gap-10 md:grid-cols-12 mb-10">
                    <div className="md:col-span-12">
                      <div className="flex items-center justify-between">
                        <p className="ff-cg--semibold">First Name</p>
                      </div>
                      <input 
                        className={"placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium"  + (formikCreate.errors.firstName == 'Required' ? ' border border-red-500' : '')}
                        name="firstName"
                        type="text"
                        onChange={formikCreate.handleChange}
                        value={formikCreate.values.firstName}
                      />
                    </div>
                    <div className="md:col-span-12">
                      <div className="flex items-center justify-between">
                        <p className="ff-cg--semibold">Last Name</p>
                      </div>
                      <input 
                        className={"placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium"  + (formikCreate.errors.lastName == 'Required' ? ' border border-red-500' : '')}
                        name="lastName"
                        type="text"
                        onChange={formikCreate.handleChange}
                        value={formikCreate.values.lastName}
                      />
                    </div>
                    <div className="md:col-span-12">
                      <div className="flex items-center justify-between">
                        <p className="ff-cg--semibold">Email Address</p>
                      </div>
                      <input 
                        className={"placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium"  + (formikCreate.errors.email == 'Required' ? ' border border-red-500' : '')}
                        name="email"
                        type="email"
                        onChange={formikCreate.handleChange}
                        value={formikCreate.values.email}
                      />
                    </div>
                    <div className="md:col-span-12">
                      <div className="flex items-center justify-between">
                        <p className="ff-cg--semibold">Phone Number</p>
                      </div>
                      <MuiTelInput 
                         className={'w-full bg-gray-100 placeholder:text-[#000000] focus:outline-none !rounded-xl mt-2' + (formikCreate.errors.mobilePhone == 'Required' ? ' !border-solid !border !border-red-500' : '')}
                         name="mobilePhone"
                         defaultCountry={'US'}
                        onChange={(value) => formikCreate.setFieldValue("mobilePhone", value)}
                        value={formikCreate.values.mobilePhone}
                      />
                    </div>
                    <div className="md:col-span-12">
                      <div className="flex items-center justify-between">
                        <p className="ff-cg--semibold">Password</p>
                      </div>
                      <input 
                        className={"placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium"  + (formikCreate.errors.password == 'Required' ? ' border border-red-500' : '')}
                        name="password"
                        type="password"
                        onChange={formikCreate.handleChange}
                        value={formikCreate.values.password}
                      />
                      <StrongPassword value={formikCreate.values.password} validate={formikCreate.errors.password == 'Required' && formikCreate.values.password === ''} callback={(event: boolean) => handlePassword(event)}/>
                    </div>
                    <div className="md:col-span-12">
                      <p className="ff-cg--semibold mb-2">Avatar Picture</p>
                      <div className="flex justify-center items-center w-full">
                        <label form="profile_url" className="flex flex-col justify-center items-center w-full h-64 bg-[#f7f7f7] rounded-lg border-2 border-gray-300 border-dashed cursor-pointer">
                          <div className="flex flex-col justify-center items-center pt-5 pb-6">
                            <svg aria-hidden="true" className="mb-3 w-20 h-20 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                            <p className="mb-2 text-[18px] ff-cg--medium">Drag and drop your file, or  <span className="underline">Browse</span></p>
                          </div>
                          <input
                            id="profile_url"
                            name="profile_url"
                            type="file"
                            className="hidden"
                            onChange={(event: any) => formikCreate.setFieldValue('profile_url',event.target.files[0])}
                            value={""}/>
                        </label>
                      </div> 
                    </div>
                  </div>
                  <div className="md:flex items-center justify-center gap-4 lg:gap-10">
                    <Link to="/students"  className="w-full md:w-[200px] flex items-center justify-center border border-[#222222] py-[14px] px-[16px] rounded-2xl mb-4 md:mb-0">
                      <span className="ff-cg--semibold">Return</span>
                    </Link>              
                    <button type="submit" className={"flex items-center justify-center bg-[#fdbf38] h-[52px] px-[16px] rounded-2xl w-full md:w-[200px] " + ( submitLoader ? '!bg-[#da1a32] justify-between' : '')}>
                      <span className={"ff-cg--semibold " + ( submitLoader ? 'text-white' : '')}>Create Student</span>
                      <Loader open={submitLoader} dimension={"8"} min={"8"} wrapClass={''}/>
                    </button>
                  </div>
                </div>
              </div>
            </form>
            </div>

          </section>

          {/* footer */}
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default Students;
export { Head } from "../components/Layout/Head";
